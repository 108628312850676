<!-- 账号安全 -->
<template>
  <div class="AccountSecurity">
    <div class="mainInfo">
      <template v-for="item in list">
        <div class="item" :key="item.name" @click="clickEvent(item.name)">
          <p>{{ item.name }}</p>
          <img class="right" src="@/assets/images/right_arrow.png" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSecurity",
  data () {
    return {
      list: [
        {
          name: "注销"
        }
      ]
    };
  },
  methods: {
    clickEvent (name) {
      if (name === "注销") {
        this.$router.push({ name: "Cancellation" });
      }
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.AccountSecurity {
  .mainInfo {
    background: #ffffff;
    padding: 0 0.2rem;
    border-radius: 0.08rem;
    margin: 0.1rem 0.18rem;
    .item {
      border-bottom: 1px solid #f1f2f6;
      font-size: 0.15rem;
      color: $mainBlack;
      display: flex;
      align-items: center;
      height: 0.58rem;
      justify-content: space-between;
      img {
        width: 0.07rem;
        height: 0.13rem;
      }
    }
  }
}
</style>
